import type { AddressBase } from '@/types/schema';
import { Typography } from '@mui/material';
import CountryFlag from './countryFlag';

export default function AddressWithFlag( { address }: { address?: AddressBase } ) {
	if ( !address ) return null;
	
	return (
		<Typography sx={{ wordBreak: 'break-word' }} component='span'>
			{[ address.line1,
			   address.line2,
			   address.city,
			   address.state,
			   address.country,
			   address.postalCode,
			].filter( Boolean ).join( ', ' )}
			<CountryFlag
				sx={{ ml: 1, display: 'inline' }}
				country={address.country}
			/>
		</Typography>
	);
}
